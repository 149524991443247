import * as React from "react";
import Helmet from "react-helmet";

interface Props {
  readonly title: string;
  readonly description?: string;
  readonly lang?: string;
  readonly keywords?: string[];
}

const Head: React.FC<Props> = ({
  title,
  description,
  lang,
  keywords,
}): React.ReactElement | null => {
  const metaDescription = description;
  lang = lang || "en";
  keywords = keywords || [];
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(
        keywords.length > 0
          ? {
              name: `keywords`,
              content: keywords.join(`, `),
            }
          : [],
      )}
    />
  );
};

export default Head;
