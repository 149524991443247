export const parallax = (image: any) => {
  return {
    /* The image used */
    background: `linear-gradient(
      rgba(1, 0, 0, 0.45), 
      rgba(0, 0, 0, 0.9)
    ), url(${image}) rgb(255, 255, 255, 0.5) no-repeat center`,
    /* Full height */
    height: "100%",
    minHeight: "812px",
    /* Create the parallax scrolling effect */
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
    backgroundSize: "cover",
    "@media(min-width: 800px)": {
      backgroundAttachment: "fixed",
      backgroundPosition: "center",
    },
  };
};

export const blurredBox = (width: number, height: number) => {
  return {
    position: "relative",
    width: `${width}px`,
    height: `${height}px`,
    top: "calc(50% - 175px)",
    left: "calc(50% - 125px)",
    background: "inherit",
    borderRadius: "2px",
    overflow: "hidden",

    ":after": {
      content: '""',
      width: "300px",
      height: "300px",
      background: "inherit",
      position: "absolute",
      left: "-25px",
      //  left position
      right: "0",
      top: "-25px",
      //  top position
      bottom: "0",
      boxShadow: "inset 0 0 0 200px rgba(255,255,255,0.05)",
      filter: "blur(10px)",
    },
  };
};

export const invisibleScroll = {
  "::webkitScrollbar": {
    width: "10px",
    overflow: "overlay",
    display: "none",
  },
  "::webkitScrollbarTrack": {
    background: "transparent",
  },
  "::webkitScrollbarThumb": {
    background: "transparent",
    borderRadius: "5px",
  },
  "::webkitScrollbarThumb:hover": {
    background: "black",
  },
  "::webkitScrollbarCorner": {
    background: "transparent",
  },
};

export const neon = (textColor: string, glowColor: string) => {
  return {
    color: textColor,
    textShadow: `0 0 5px ${textColor}, 0 0 10px ${textColor}, 0 0 15px ${textColor}, 0 0 20px ${glowColor}, 0 0 30px ${glowColor}, 0 0 40px ${glowColor}, 0 0 55px ${glowColor}, 0 0 75px ${glowColor}`,
  };
};

export const glow = (textColor: string, glowColor: string) => {
  return {
    color: textColor,
    textShadow: `${textColor} 0px 0px 6px, ${textColor} 0px 0px 3px, ${glowColor} 0px 0px 13px, ${glowColor} 0px 0px 20px, ${glowColor} 0px 0px 36px, ${glowColor} 0px 0px 14px, ${glowColor} 0px 0px 27px, ${glowColor} 0px 0px 0px`,
  };
};
export const FontFamily = {
  lobster: "'Lobster', cursive",
};

export const defaultStyles = {
  font: {
    h1: {
      color: "white",
      fontFamily: "'Roboto', sans-serif",
      fontSize: "35px",
      paddingLeft: 0,
      marginLeft: 0,
    },
    body: {
      color: "white",
      fontFamily: "'Roboto', sans-serif",
      letterSpacing: "1px",
      fontSize: "15px",
      lineHeight: "17px",
      padding: "10px",
      paddingLeft: 0,
      marginLeft: 0,
    },
  },
  button: {
    primary: {
      alignItems: "center",
      background: "black",
      border: 0,
      borderRadius: "4px",
      color: "white",
      cursor: "pointer",
      display: "flex",
      fontWeight: "bold",
      gridGap: "4px",
      margin: 0,
      outline: "none",
      padding: "4px 6px 4px 6px",
    },
  },
};
